<template>
  <div>
    <b-row style="margin-bottom: 20px;">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" md="8">
        <strong style="font-size: 30px; font-family: cursive;">{{ d_pageInfoData.page_caption }}</strong>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-row v-if="d_pageInfoData">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="(page_info, page_info_index) in d_pageInfoData.page_info">
          <page-info :p_pageInfo="page_info"></page-info>
          <hr>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <!-- {{ d_reports }} -->
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="report_type in d_reports.paramorder">
          <template v-if="d_reports.parameters[report_type] && d_reports.parameters[report_type].report_list && d_reports.parameters[report_type].report_list.length > 0">
            <analyze-report-view :p_analyzeReports="d_reports.parameters[report_type].report_list"></analyze-report-view>
          </template>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import WamserverService from '@/services/wamserver';
import AnalyzeReportView from '@/components/widgets/AnalyzeReportView';
import _menu from '@/_menu';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { version } from '@/config';
import { mapGetters } from 'vuex';
export default {
  name: 'wanalyzer',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    PageInfo,
    AnalyzeReportView,
  },
  props: {},
  data () {
    return {
      d_reportTypeList: [
        {'value': 'wanalyzer_active_project_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_passive_project_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_about_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_auto_data_update_algorithm_run_run_algorithms', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_auto_data_update_machine_learning_models_update_machine_learning_models', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_auto_data_update_stat_models_update_statistic_models', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_auto_update_yes', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_case_limit_yes', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_data_updating_status_cancelled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_data_updating_status_completed', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_data_updating_status_continuing', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_data_updating_status_not_started', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_1', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_12', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_13', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_14', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_15', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_16', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_17', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_18', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_19', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_2', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_20', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_21', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_22', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_23', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_3', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_4', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_5', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_6', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_hours_7', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_months_2', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_months_3', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_defined_months_4', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_delete_dataset_after_x_start_data_prepation_yes', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_delete_not_including_positive_algorithm_columns_yes', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_dmp_list_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_interval_type_day', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_interval_type_defined_hours_in_day', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_interval_type_hour', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_interval_type_minute', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_last_completed_add_new_cases_date_is_today', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_last_data_update_total_time_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_last_info_date_is_today', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_last_wmanager_case_list_count_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_last_wmanager_operation_total_seconds_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_layer_data_usage_type_while_updating_dataset_use_all_data', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_layer_data_usage_type_while_updating_dataset_use_updated_data_in_layer_add_or_edit', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_layer_list_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_machine_learning_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_name_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_new_data_starting_point_type_continue_from_offset_point', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_new_data_starting_point_type_from_the_beginning', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_new_data_starting_point_type_last_dataset_last_id', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_positive_algorithm_count_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_positive_algorithm_count_expected_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_process_start_date_of_algorithm_analyze_is_today', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_process_start_date_of_data_update_is_today', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_product_code_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_data_type_api', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_data_type_layer', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_data_type_manuel', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_data_type_mssql', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_cancelled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_designing', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_planning', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_testing', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_under_maintenance', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_project_life_cycle_status_working', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_rows_new_count_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_rows_new_count_expected_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_run_in_defined_months_yes', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_digital_marketing', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_report', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_research', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_sending_a_customer_satisfaction_survey', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_smart_billing', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_scope_type_test', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_sql_list_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_statistics_is_filled', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_total_added_case_count_count', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_update_type_delete_all_and_recreate', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_update_type_only_add_new_cases', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_update_type_update_dataset_all_cells_add_new_data_not_delete_algorithm_columns', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_wmanager_data_prepare_type_classic', 'prepare_type': 'last_one_month'},
        {'value': 'wanalyzer_wmanager_data_prepare_type_data_profile', 'prepare_type': 'last_one_month'},
      ],
      d_reports: { 'parameters': {}, 'paramorder': [] },
      d_pageInfoData: '',
      d_pageName: 'wanalyzer',
      d_version: version,
      d_sideBarMenuList: _menu.website_data.page_list,
    };
  },
  created: function () {
    this.f_getReports();
  },
  beforeMount () {},
  mounted: function () {
    let is_page_found = false;
    for (let i in this.d_sideBarMenuList) {
      if (this.d_sideBarMenuList[i].route_name === this.d_pageName) {
        this.d_pageInfoData = this.d_sideBarMenuList[i];
        is_page_found = true;
        break;
      } else {
        if (this.d_sideBarMenuList[i].sub_menu && this.d_sideBarMenuList[i].sub_menu.length > 0) {
          for (let sub_i in this.d_sideBarMenuList[i].sub_menu) {
            if (this.d_sideBarMenuList[i].sub_menu[sub_i].route_name === this.d_pageName) {
              this.d_pageInfoData = this.d_sideBarMenuList[i].sub_menu[sub_i];
              is_page_found = true;
              break;
            }
          }
        }
      }
      if (is_page_found) {
        break;
      }
    }
  },
  methods: {
    f_getReports: function () {
      this.d_reports = { 'parameters': {}, 'paramorder': [] };
      let query = '';
      let data = { 'report_type_list': this.d_reportTypeList };
      WamserverService.get_reports(query, data)
        .then(resp => {
          if (resp.data.status_code === "7000") {
            this.d_reports = resp.data.report_list;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    }
  },
  watch: {}
}

</script>


